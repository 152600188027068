import React from 'react';
import './App.css';
import CircleAnimation from "./components/circle-animation/circle-animation";
import Header from "./components/header/header";
import SocialLinks from "./components/social-links/social-links";

function App() {
  return (
    <div className="App">
        <Header />
        <CircleAnimation />
        <SocialLinks />
        <div className="email">
            <span>avivmoshe96@gmail.com</span>
            <div>
                <span>Tel Aviv, Israel</span>
            </div>
        </div>
    </div>
  );
}

export default App;
