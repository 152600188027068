import React, {useEffect, useState} from "react";
import classNames from "classnames";

import './profile.css';

interface Props {
    onClick?: () => void
    className?: string
}

const Profile: React.FC<Props> = ({className, onClick}) => {
    const [active, setActive] = useState<boolean>(false);

    return (
        <div className={classNames("profile", className, {active})}  onAnimationEnd={() => setActive(false)} onClick={() => {
            setActive((active) => !active)
            onClick && onClick()
        }}/>
    )
}

export default Profile;