import React, {useEffect, useState} from "react";
import { ReactComponent as Circle1} from '../../assets/images/image-circle/index-portal-blue.svg';
import { ReactComponent as Circle2} from '../../assets/images/image-circle/index-portal-blue-semi.svg';
import { ReactComponent as Circle3} from '../../assets/images/image-circle/index-portal-green.svg';
import { ReactComponent as Circle4} from '../../assets/images/image-circle/index-portal-orange.svg';
import { ReactComponent as Circle5} from '../../assets/images/image-circle/index-portal-red.svg';
import { ReactComponent as Circle6} from '../../assets/images/image-circle/index-portal-green-semi.svg';
import { ReactComponent as Circle7} from '../../assets/images/image-circle/index-portal-orange-semi.svg';
import { ReactComponent as Circle8} from '../../assets/images/image-circle/index-portal-red-semi.svg';
import { ReactComponent as Circle9} from '../../assets/images/image-circle/index-portal-yellow.svg';
import { ReactComponent as Circle10} from '../../assets/images/image-circle/index-portal-yellow-semi.svg';

import './circle-animation.css';
import Profile from "../profile/profile";

enum CircleSpeed {
    OneX = '30s',
    TwoX = '15s',
    ThreeX = '7.5s',
    FourX = '3.75s',
    FiveX = '1.875s',
    SixX = '0.9375s',
}

const speeds = [CircleSpeed.OneX, CircleSpeed.TwoX, CircleSpeed.ThreeX, CircleSpeed.FourX, CircleSpeed.FiveX, CircleSpeed.SixX];

const CircleAnimation: React.FC = () => {
    const [speed, setSpeed] = useState<number>(0);

    useEffect(() => {
        document.documentElement.style.setProperty('--base-animation-speed', speeds[speed].toString());
    }, [speed]);

    const updateSpeed = () => {
        setSpeed(speed => speed < speeds.length -1 ? ++speed : 0);
    }

    return (
        <div className="circle-animation-container">
            <Circle1 className="circle-animation" />
            <Circle2 className="circle-animation" />
            <Circle3 className="circle-animation" />
            <Circle4 className="circle-animation" />
            <Circle5 className="circle-animation" />
            <Circle6 className="circle-animation" />
            <Circle7 className="circle-animation" />
            <Circle8 className="circle-animation" />
            <Circle9 className="circle-animation" />
            <Circle10 className="circle-animation" />
            <Profile className="profile-in-animation" onClick={updateSpeed} />
        </div>
    )
};

export default CircleAnimation;