import React from "react";
import './header.css';

const Header: React.FC = () => {
    return (
        <div className="header">
            <h3 className="title">Hello, I'm Aviv Moshe, Software Developer from Tel Aviv</h3>
        </div>
    )
}

export default Header;