import React from "react";
import {SocialIcon} from "react-social-icons";
import './social-links.css';

const SocialLinks: React.FC = () => {
    return (
        <div>
            <SocialIcon className="social-link" fgColor="white" url="https://www.linkedin.com/in/avivmoshe" />
            <SocialIcon className="social-link" fgColor="white" url="https://twitter.com/avivmoshe6" />
            <SocialIcon className="social-link" fgColor="white" url="https://www.facebook.com/AvivFTW" />
            <SocialIcon className="social-link" bgColor="white" url="https://github.com/AvivM96" />
            <SocialIcon className="social-link" bgColor="white" fgColor="#dc4235" network="email" onClick={() => window.open('mailto:avivmoshe96@gmail.com')}/>
        </div>
    )
}

export default SocialLinks;